<template>

  <div class="content" id="content">

    <headerNav/>

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

  </div>

  <footerNav/>

</template>

<script>
import headerNav from './vue/components/headerNav.vue'
import footerNav from './vue/components/footerNav.vue'

export default {
  name: 'App',
  components: {
    headerNav,
    footerNav
  },
}
  
</script>

<style>
body, html {
  background-color: #f5f5f5;
  min-height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
}

#app {
  font-family: 'Amatic SC', cursive;
  font-weight: 700;
  background-color: #f5f5f5;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  width: 100%;
}

button, input, textarea {
  font-family: 'Amatic SC', cursive;
}

.content {
  width: 100%;
  min-height: 100vh;
}



/* Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #757474;
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757474; 
}


/* Page transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Classes to use all over webpage */

.drawn-border {
  border:solid 3px #000000;
  background: transparent;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius:15px 255px;
}

.back-btn-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  padding: 10px;
}

.back-btn-btn {
  font-size: 40px;
  font-weight: 700;
  padding: 0px;
  -webkit-appearance: none; color:#000000 !important ; /* Safari button color */
  background: transparent;
  border: none;
  cursor: pointer;
}

.back-btn-arrow {
  width: 50px;
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.back-btn-container button:hover ~ .back-btn-arrow {
  transform: translateY(0px);
  opacity: 1;
}

.btn-normal {
  font-size: 30px;
  font-weight: 700;
  padding: 5px;
  margin: 10px;
  background: transparent;
  -webkit-appearance: none; color:#000000 !important ; /* Safari button color */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btn-normal:hover {
  background: #a3a3a3;
}


.pricing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pricing-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.pricing-container img {
  width: 50px;
  height: 50px;
}
.pricing-container p {
  margin: 0;
}


</style>