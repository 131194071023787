<template>
  <div class="blogs-main">
    <h1>Blog</h1>
    <div class="blogs-offers">
      <blog v-for="data in blogsData" :data="data"/>
    </div>
  </div>
</template>

<script>
import blog from '../components/blog.vue'

export default {
  name: 'blogs',
  components: {
    blog
  },
  computed: {
    blogsData() {
      return this.$store.state.data.blogs
    }
  }
}

</script>

<style>

.blogs-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.blogs-main > h1 {
  font-size: 60px;
  margin: 0;
}

.blogs-offers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media screen and (max-width: 550px) {
  .blogs-main > h1 {
    text-align: center;
  }
}

</style>