<template>
  <div class="contact-main">

    <h1>Kontakt</h1>
    <div class="contact-content">

      <div @click="callMe" class="contact-phone">
        <img src="../../assets/phone.svg" alt="phone">
        <p>Telefon</p>
        <p>+420 607 536 103</p>
      </div>

      <div @click="mailMe" class="contact-mail">
        <img src="../../assets/mail.svg" alt="mail">
        <p>E-mail</p>
        <p>iva.kubitova@gmail.com</p>
      </div>

      <div @click="instagram" class="contact-instagram">
        <img src="../../assets/instagram.svg" alt="instagram">
        <p>Instagram</p>
        <p>@iva.kubitova</p>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'contact',
  methods: {
    callMe() {
      window.location.href = 'tel:+420607536103';
    },
    mailMe() {
      window.location.href = 'mailto:iva.kubitova@gmail.com';
    },
    instagram() {
      window.open('https://www.instagram.com/iva.kubitova/', '_blank');
    }
  }
}


</script>

<style>

.contact-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-main h1 {
  font-size: 60px;
  margin: 0;
}

.contact-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact-phone, .contact-mail, .contact-instagram {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
  cursor: pointer;
}

.contact-phone img, .contact-mail img, .contact-instagram img {
  width: 250px;
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
}

.contact-phone p, .contact-mail p, .contact-instagram p {
  font-size: 35px;
  margin: 5px;
}

.contact-phone img:hover {
  transform: scale(1.1) rotate(-20deg);
}

.contact-mail img:hover {
  transform: translateX(20px) rotate(-10deg);
}

.contact-instagram img:hover {
  transform: translateX(-20px) rotate(-10deg);
}



</style>