<template>
<div class="notFound-main">
  <div class="lamp__wrap">
    <div class="lamp">
      <div class="cable"></div>
      <div class="cover"></div>
      <div class="in-cover">
        <div class="bulb"></div>
      </div>
      <div class="light"></div>
    </div>
  </div>
  <!-- END Lamp -->
  <section class="error">
    <!-- Content -->
    <div class="error__content">
      <div class="error__message message">
        <h1 class="message__title">Stránka nenalezena 404</h1>
      </div>
    </div>
    <!-- END Content -->

  </section>
</div>

</template>

<style>

.notFound-main {
  background-color: #181828;
  height: 700px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.lamp {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: 0px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: center top;
  animation-timing-function: cubic-bezier(0.6, 0, 0.38, 1);
  animation: move 5.1s infinite;
}

@keyframes move {
  0% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(40deg);
  }
}

.cable {
  width: 8px;
    height: 148px;
    background-color: #9c9c9c;
}

.cover {
  width: 200px;
  height: 80px;
  background: #757474;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: relative;
  z-index: 200;
}

.in-cover {
  width: 100%;
  max-width: 200px;
  height: 20px;
  border-radius: 100%;
  background: #757474;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  bottom: -9px;
  z-index: 100;
}
.in-cover .bulb {
     width: 50px;
    height: 50px;
    background-color: #d6d600;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -20px;
    margin: 0px auto;
    box-shadow: 0 0 25px 7px rgb(255 255 0 / 80%), 0 0 64px 47px rgba(255,255,255,0.5), 0px 0 30px 15px rgba(0,255,255,0.2);
}


.light {
      width: 200px;
    height: 0px;
    border-bottom: 900px solid rgb(255 255 0 / 24%);
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 200px;
    margin: 0px auto;
    z-index: 1;
    border-radius: 90px 90px 0px 0px;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.error__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.error__content {
  position: absolute;
  top: 50%;
  width: 100%;
}

.error__message {
  text-align: center;
  color: #181828;
}

.message__title {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 10px;
   font-size: 5.6rem;
  max-width: 960px;
  margin: 0 auto;
}

.message__text {
  line-height: 42px;
  font-size: 18px;
  padding: 0px;
  max-width: 680px;
  margin: auto;
}

/* 2.1. END Section Error */

</style>