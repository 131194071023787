import { createStore } from 'vuex'

import workshopData from '../data/workshops.json'
import consultationsData from '../data/consultations.json'
import blogsData from '../data/blogs.json'

export default createStore({
  state: {
    data: {
      workshops: workshopData,
      consultations: consultationsData,
      blogs: blogsData
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
