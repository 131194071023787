<template>

<div class="detail-main">

  <div class="detail-header">

    <div class="back-btn-container">
      <button class="back-btn-btn" @click="goBack">Zpět</button>
      <img src="../../assets/arrow_left.svg" class="back-btn-arrow">
    </div>

  </div>

  <div class="detail-content drawn-border">

    <h1>{{ serviceData.title }}</h1>
    <p>{{ serviceData.detail.text }}</p>


    <div class="pricing-container">
      <div>
        <img src="../../assets/wallet.svg" alt="price">
        <p>{{ serviceData.price }}</p>
      </div>

      <div>
        <img src="../../assets/clock.svg" alt="duration">
        <p>{{ serviceData.duration }}</p>
      </div>
    </div>

    <button class="btn-normal drawn-border" @click="order">Objednat</button>
  </div>
  
</div>

</template>

<script>

export default {
  name: 'detail',

  computed: {
    params() {
      return this.$route.params;
    },
    serviceData() {
      if (this.params.type == 'konzultace') {
        return this.$store.state.data.consultations.find(consultation => consultation.id == this.params.id);
      } else {
        return this.$store.state.data.workshops.find(workshop => workshop.id == this.params.id);
      }
    }
  },
  methods: {
    goBack() {
      if (this.serviceData.type == 'konzultace') {
        this.$router.push('/konzultace');
      } else {
        this.$router.push('/workshopy');
      }
    },
    order() {
      this.$router.push(`/order/${this.serviceData.type}/${this.serviceData.id}`);
    }
  }
}

</script>


<style>

.detail-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.detail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.detail-content h1 {
  font-size: 50px;
}
.detail-content p {
  font-size: 25px;
  padding: 15px;
}


@media screen and (max-width: 860px) {
  .detail-content {
    width: 90%;
  }
}

</style>