<template>

<div @click="redirect" class="blog-main drawn-border">

  <div>
    <h1>{{ data.title }}</h1>
    <p v-html="description"></p>
    <p>Číst více</p>
  </div>

</div>

</template>

<script>

export default {
  name: 'blog',
  props: ['data'],
  computed: {
    description() {
      return this.data.text.substring(0, this.data.text.indexOf(" ", 150)) + '...';
    }
  },
  methods: {
    redirect() {
      this.$router.push(`/blog/${this.data.id}`);
    }
  }
}

</script>

<style>

.blog-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.blog-main:hover {
  transform: scale(1.1);
}
.blog-main h1 {
  font-size: 40px;
  margin: 0;
}
.blog-main p {
  font-size: 25px;
  font-weight: 1000;
  padding: 0px;
  margin: 0px;
}

</style>