<template>
  <div class="consultations-main">
    <h1>Konzultace</h1>
    <div class="consultations-offers">
      <consultation v-for="data in consultationsData" :data="data"/>
    </div>
  </div>
</template>

<script>
import consultation from '../components/consultation.vue'

export default {
  name: 'consultations',
  components: {
    consultation
  },
  computed: {
    consultationsData() {
      return this.$store.state.data.consultations
    }
  }
}

</script>

<style>

.consultations-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.consultations-main > h1 {
  font-size: 60px;
  margin: 0;
}

.consultations-offers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

</style>