<template>

<div class="blog-detail-main">

  <div class="blog-detail-header">

    <div class="back-btn-container">
      <button class="back-btn-btn" @click="goBack">Zpět</button>
      <img src="../../assets/arrow_left.svg" class="back-btn-arrow">
    </div>

  </div>

  <div class="blog-detail-content drawn-border">

    <h1>{{ blogData.title }}</h1>
    <p v-html="blogData.text"></p>

  </div>
  
</div>

</template>

<script>

export default {
  name: 'blog-detail',

  computed: {
    params() {
      return this.$route.params;
    },
    blogData() {
      return this.$store.state.data.blogs.find(blog => blog.id == this.params.id);
    }
  },
  methods: {
    goBack() {
      this.$router.push('/blogy');
    },
  }
}

</script>


<style>

.blog-detail-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-detail-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.blog-detail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.blog-detail-content h1 {
  font-size: 50px;
}
.blog-detail-content p {
  font-size: 25px;
  padding: 15px;
}


@media screen and (max-width: 860px) {
  .blog-detail-content {
    width: 90%;
  }
}

</style>