<template>
  <div class="workshops-main">
    <h1>Semináře / Workshopy</h1>
    <div class="workshops-offers">
      <workshop v-for="data in workshopsData" :data="data"/>
    </div>
  </div>
</template>

<script>
import workshop from '../components/workshop.vue'

export default {
  name: 'workshops',
  components: {
    workshop
  },
  computed: {
    workshopsData() {
      return this.$store.state.data.workshops
    }
  }
}

</script>

<style>

.workshops-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.workshops-main > h1 {
  font-size: 60px;
  margin: 0;
}

.workshops-offers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

@media screen and (max-width: 550px) {
  .workshops-main > h1 {
    text-align: center;
  }
}

</style>