<template>
<div class="header">
  <h1 id="mainTitle" @click="changePage('')">Iva Kubitová Kouč</h1>


  <div class="header-nav" id="headerNav">

    <span @click="changePage('omne')">
      <p>O mně</p>
    </span>

    <span @click="changePage('konzultace')">
      <p>Konzultace</p>
    </span>

    <span @click="changePage('workshopy')">
      <p>Semináře/Workshopy</p>
    </span>

    <span @click="changePage('blogy')">
      <p>Blog</p>
    </span>

    <span @click="changePage('kontakt')">
      <p>Kontakt</p>
    </span>

  </div>

  <a class="nav-toggle" id="nav-toggle" @click="toggleNav()"><img src="../../assets/toggle.svg"></a>

</div>
</template>

<script>
const sleep = ms => new Promise(r => setTimeout(r, ms))

export default {
  name: 'headerNav',
  data: () => ({
    navOpened: false,
  }),
  methods: {
    changePage(page) {
      this.$router.push(`/${page}`);
      if (this.navOpened) {
        this.toggleNav();
      }
    },
    async toggleNav() {
      this.navOpened = !this.navOpened;
      document.getElementById("nav-toggle").classList.toggle("active");
      document.getElementById("mainTitle").classList.toggle("hidden");
      document.getElementById("headerNav").classList.toggle("visible");
    }
  }
}

</script>

<style>

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  min-height: 80px;
}
.header-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
}
.header-nav.visible {
  opacity: 1;
  max-width: 220px;
  max-height: 300px;
}
.header-nav span, .header span {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.header h1 {
  max-width: 330px;
  wrap: nowrap;
  transition: all 0.5s ease-in-out;
}
.header h1.hidden {
  max-width: 0px;
  opacity: 0;
}
.header h1, .header p {
  font-size: 2em;
  font-size: 2em;
  cursor: pointer;
}

.header-nav span:hover {
  color: gray;
}
.header-nav .selected {
  text-decoration: overline;
}

/* Toggle button */

.nav-toggle {
  display: none;
  transition: all 0.2s ease-in-out;
}
.nav-toggle.active {
  transform: rotate(90deg);
}

.nav-toggle img {
  width: 60px;
}


/* Header responsivity */

@media screen and (max-width: 860px) { /* Put nav below main title */
  .header {
    flex-direction: column;
    align-items: center;
  }
  .header h1 {
    font-size: 3em;
  }
}

@media screen and (max-width: 550px) { /* Hide nav and display toggle icon and prepare nav to be displayed*/
  .header {
    flex-direction: row;
    align-items: start;
  }
  .header-nav {
    max-width: 0px;
    max-height: 0px;
    overflow: hidden;
    flex-direction: column;
    opacity: 0;
  }
  .header h1 {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
  }
  .header-nav p {
    margin: 2px;
  }
  .nav-toggle {
    display: block;
  }
}

@media screen and (max-width: 400px) { /* Make main title smaller*/
  .header {
    padding: 5px;
  }

  .header h1 {
    font-size: 2.5em;
    padding-top: 10px;
  }
}

</style>