<template>
  <div class="about-main">
    <img src="../../assets/about.png" alt="Tady mám být já :D">
    <div class="about-content">
      <h1>O mně</h1>
      <div>
        <p>V životě máme mnoho příležitostí někam odjet, poznat nové věci i lidi. Ale největší dobrodružství vnímám při poznávání krajiny sebe sama. Můžeme tam žít jako cizinci a nikdo nás nebude nutit poznat sebe sama. Ale když tuto cestu objevíme a půjdeme po ní, objeví se netušené možnosti, co do nás bylo vloženo a čeká až bude moct vyrůst.</p>
        <p>Nevyužité obdarování, příležitosti, jsou jako semínka, která zůstala v půdě bez užitku. Prostě shnila. Čas zastavení vnímám jako příležitost poznat sama sebe, možnost prožít život plně a v souladu se sebou, nikoli požadavky okolí. Jen tak budeme spokojení.</p>
      </div>
    </div>
  </div>
</template>

<style>

.about-main {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about-main img {
  width: 500px;
}

.about-content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-content h1 {
  font-size: 60px;
  margin: 0;
}
.about-content div{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 30px;
  padding-right: 20px;
}
.about-content p {
  width: 100%;
  height: 100%;
  margin: 5px;
}

/* responsivity */

@media screen and (max-width: 860px) {
  .about-main {
    flex-direction: column;
  }
  .about-content div {
    font-size: 25px;
  }
}

@media screen and (max-width: 550px) {
  .about-main img {
    width: 100%;
  }
  .about-content {
    width: 90%;
  }
  .about-content p {
    padding: 0px;
  }
}

</style>