<template>
<div class="footer" @click="redirect('https://www.honzakubita.net/')">
  <p>Code by honzakubita.net</p>
</div>
</template>

<script>

export default {
  name: 'footerNav',
  methods: {
    redirect(page) {
      window.open(page, '_blank');
    },
  }
}
  
</script>

<style>

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.footer p {
  font-size: 30px;
  text-decoration: underline;
}
.footer:hover {
  opacity: 1;
}

@media screen and (max-width: 550px) {
  .footer p {
    font-size: 25px;
  }
}

</style>