<template>
  <div class="home-main">
    <h1>Každý krok má svůj důvod i směr...</h1>
    <p>...a když víte co vám jde přirozeně dobře, je snazší jít kupředu.</p>
    <footsteps/>
  </div>
</template>


<script>
import footsteps from '../components/footsteps.vue'

export default {
  name: 'Home',
  data: () => ({
  }),
  components: {
    footsteps
  },
}
</script>


<style>

.home-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-main h1 {
  font-size: 5em;
  font-weight: 700;
  padding: 10px;
}
.home-main p {
  font-size: 2em;
  padding: 10px;
}

</style>