const sleep = ms => new Promise(r => setTimeout(r, ms));

function randn(min, max) { // Random number between min and max 
  return Math.random() * (max - min) + min;
}

function generatePath(width, height, offset) {
  let path = [];
  let pathLength = Math.ceil(width / offset) + 1;
  path[0] = {x: 0, y: randn(height / 4, height - height / 4)};
  for (let i = 1; i < pathLength; i++) {
    
    path[i] = {
      x: offset * i,
      y: randn(path[i - 1].y - 50, path[i - 1].y + 50)
    };
  
  }
  console.log(path);
  return path
}

async function generateFootstep(position, rotation, side) {
  let footStepE = document.createElement("img");
  footStepE.src = `assets/footstep_${side}.png`;
  footStepE.style.left = `${position.x}px`;
  footStepE.style.top = `${position.y}px`;
  footStepE.style.transform = `rotate(${rotation}deg)`;
  footStepE.classList.add("footstep");
  document.getElementById("footsteps").appendChild(footStepE);
}

async function drawFootstepsSmooth(path, offset, delay = 500) {
  for (let i = 0; i < path.length - 1; i++) {

    await sleep(delay);

    let position = path[i];
    let nextPosition = path[i + 1];
    let rotation = 275 + Math.atan2(position.y - nextPosition.y, position.x - nextPosition.x) * 180 / Math.PI;
  
    await generateFootstep( // First step
      {
      x: position.x,
      y: position.y + 25
    },
    rotation - 5, "right");

    await sleep(delay);

    await generateFootstep( // Second step
    {
      x: position.x + offset / 2,
      y: position.y - 25
    }, 
    rotation + 5, "left");

  }
  await sleep(3000); // Has to be same as animation length
  document.getElementById("footsteps").innerHTML = "";
}

// function drawAlgorithmCanvas(path) {
//   let canvas = document.getElementById("footsteps");
//   let ctx = canvas.getContext("2d");
//   ctx.clearRect(0, 0, canvas.width, canvas.height);
//   for (let i = 1; i < path.length; i++) {
//     ctx.beginPath();
//     ctx.moveTo(path[i-1].x, path[i-1].y);
//     ctx.lineTo(path[i].x, path[i].y);
//     ctx.rect(path[i].x-5, path[i].y-5, 10, 10);
//     ctx.stroke();
//   }
// }

export async function footstepsLoop() {
  let div = document.getElementById("footsteps");

  let divX = div.clientWidth;
  let divY = div.clientHeight;
  console.log(`X: ${divX}, Y: ${divY}`);

  
  let direction = Math.random() > 0.5 ? "left" : "right";
  console.log(`Direction: ${direction}`);
  div.style.transform = `rotate3d(0, ${direction === "left" ? "180" : "0"}, 0, 180deg)  translateY(-50%)`;
  
  let offset = 250;

  let path = generatePath(divX, divY, offset);
  //drawAlgorithmCanvas(path);
  await drawFootstepsSmooth(path, offset, 500);
  requestAnimationFrame(footstepsLoop);
}