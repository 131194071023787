<template>

<div class="order-main">

  <div class="detail-header">

    <div class="back-btn-container">
      <button class="back-btn-btn" @click="goBack">Zpět</button>
      <img src="../../assets/arrow_left.svg" class="back-btn-arrow">
    </div>

  </div>

  <div class="order-content drawn-border">
    <h1>Objednávka - {{ serviceData.type }}</h1>

    <h1 v-if="done">Děkuji za objednávku</h1>
    <div v-else>
      <form @submit.prevent="order" class="order-form">

        <p>Jméno:</p>
        <input v-model="form.name" name="name" type="text" class="drawn-border" required>

        <p>Příjmení</p>
        <input v-model="form.surname" name="surname" type="text" class="drawn-border" required>

        <p>Email:</p>
        <input v-model="form.email" name="email" type="email" class="drawn-border" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required>

        <p>Poznámka:</p>
        <textarea v-model="form.note" name="note" class="drawn-border"></textarea>

        <button class="btn-normal drawn-border">Objednat</button>
      </form>

      <div class="order-product drawn-border">
        <h1>{{ serviceData.title }}</h1>
        <p>{{ serviceData.detail.text }}</p>
        <p>Cena: {{ serviceData.price }}</p>
        <p>Doba trvání: {{ serviceData.duration }}</p>
      </div>
    </div>


  </div>

</div>

</template>


<script>

export default {
  name: 'order',
  data: () => ({
    done: false,
    form: {
      name: '',
      surname: '',
      email: '@',
      note: ''
    }
  }),
  computed: {
    params() {
      return this.$route.params;
    },
    serviceData() {
      if (this.params.type == 'konzultace') {
        return this.$store.state.data.consultations.find(consultation => consultation.id == this.params.id);
      } else {
        return this.$store.state.data.workshops.find(workshop => workshop.id == this.params.id);
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push(`/detail/${this.serviceData.type}/${this.serviceData.id}`);
    },
    order() {
      this.done = true;
      emailjs.send("service_7siyw9p","template_7mcqx2k",{
        type: this.serviceData.type,
        name: this.form.name,
        surname: this.form.surname,
        email: this.form.email,
        product: this.serviceData.title,
        note: this.form.note,
      });
    }
  }
}

</script>


<style>

.order-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-header {
  display: flex;
  width: 100%;
}

.order-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.order-content > div {
  display: flex;
  flex-direction: row;
}
.order-content h1 {
  text-align: center;
  font-size: 50px;
}
.order-content p {
  font-size: 25px;
  padding: 15px;
}

.order-product {
  width: 50%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-product p {
  padding: 5px;
  margin: 5px;
}

/* Order form */

.order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin: auto;
}
.order-form input {
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}
.order-form p {
  font-size: 20px;
  margin: 0;
  padding: 0;
}
.order-form textarea {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}


@media screen and (max-width: 860px) {
  .order-content {
    width: 90%;
  }
  .order-content div {
    flex-direction: column;
  }
  .order-form {
    width: 50%;
  }
  .order-product {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .order-form {
    width: 80%;
  }
}

</style>