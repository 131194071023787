import { createRouter, createWebHistory } from 'vue-router'

import home from '../vue/pages/home.vue'
import about from '../vue/pages/about.vue'
import consultations from '../vue/pages/consultations.vue'
import workshops from '../vue/pages/workshops.vue'
import blogs from '../vue/pages/blogs.vue'
import contact from '../vue/pages/contact.vue'

import blogDetail from '../vue/pages/blogDetail.vue'
import detail from '../vue/pages/detail.vue'
import order from '../vue/pages/order.vue'


import notFound from '../vue/pages/notFound.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/omne',
    name: 'about',
    component: about
  },
  {
    path: '/konzultace',
    name: 'consultations',
    component: consultations
  },
  {
    path: '/workshopy',
    name: 'workshops',
    component: workshops
  },
  {
    path: '/blogy',
    name: 'blogs',
    component: blogs
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: contact
  },

  {
    path: '/blog/:id',
    name: 'blogDetail',
    component: blogDetail
  },

  {
    path: '/detail/:type/:id',
    name: 'consultationDetail',
    component: detail
  },

  {
    path: '/order/:type?/:id?',
    name: 'order',
    component: order
  },
  

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: notFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0, behavior: 'smooth'}
  }
})

export default router
