<template>

<div @click="redirect" class="consultation-main drawn-border">

  <div>
    <h1>{{ data.title }}</h1>
    <p>{{ data.description }}</p>
  </div>

  <div class="pricing-container">

    <div>
      <img src="../../assets/wallet.svg" alt="price">
      <p>{{ data.price }}</p>
    </div>

    <div>
      <img src="../../assets/clock.svg" alt="duration">
      <p>{{ data.duration }}</p>
    </div>

  </div>

</div>

</template>

<script>

export default {
  name: 'consultation',
  props: ['data'],
  computed: {
    hasImg() {
      return this.data.imgSrc != '';
    }
  },
  methods: {
    redirect() {
      this.$router.push(`/detail/konzultace/${this.data.id}`)
    }
  }
}

</script>

<style>

.consultation-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  padding: 20px;
  margin: 20px auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.consultation-main:hover {
  transform: scale(1.1);
}
.consultation-main h1 {
  font-size: 40px;
  margin: 0;
}
.consultation-main p {
  font-size: 25px;
  font-weight: 1000;
  padding: 0px;
  margin: 0px;
}

</style>