<template>

<div @click="redirect" class="workshop-main drawn-border">

  <div>
    <h1>{{ data.title }}</h1>
    <p>{{ data.type }}</p>
    <p>{{ data.description }}</p>
  </div>

  <div class="pricing-container">

    <div>
      <img src="../../assets/wallet.svg" alt="price">
      <p>{{ data.price }}</p>
    </div>

    <div>
      <img src="../../assets/clock.svg" alt="duration">
      <p>{{ data.duration }}</p>
    </div>

  </div>
</div>

</template>

<script>

export default {
  name: 'workshop',
  props: ['data'],
  methods: {
    redirect() {
      if (this.data.type == 'workshop') {
        this.$router.push(`/detail/workshop/${this.data.id}`);
      } else {
        this.$router.push(`/detail/seminar/${this.data.id}`)
      }
    }
  }
}

</script>

<style>

.workshop-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  padding: 20px;
  margin: 20px auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.workshop-main:hover {
  transform: scale(1.1);
}
.workshop-main h1 {
  font-size: 40px;
  margin: 0;
}
.workshop-main p {
  font-size: 25px;
  font-weight: 1000;
  padding: 0px;
  margin: 0px;
}

</style>