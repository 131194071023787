<template>
  <div id="footsteps" class="footsteps"></div>
</template>


<script>

// //////////////////////////////////
import { footstepsLoop } from './footsteps.js'


export default {
  name: 'footsteps',
  data: () => ({
  }),
  mounted() {
    footstepsLoop()
  }
}
</script>

<style>

.footsteps {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 500px;
  overflow: hidden;
  z-index: 1;
  position: relative;
}

.footsteps img {
  position: absolute;
  height: 100px;
  animation: fadeIn 3s ease;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>